// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/assets/Background-floral.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/src/assets/Background-pattern.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("/src/assets/Background-decarto.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --bg-img-floral: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    --bg-img-pattern: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    --bg-img-decarto: url(${___CSS_LOADER_URL_REPLACEMENT_2___});

    --bg-overlay-main: rgba(20, 19, 17, 0.6);
    --bg-overlay-stack: rgba(95, 95, 95, 0.5);
    --bg-overlay-decarto-light: rgba(250, 250, 250, 0.4);
    --bg-overlay-decarto-dark: rgba(0, 0, 0, 0.2);
    --bg-overlay-mfb: rgba(72, 72, 72, 0.7);
    --bg-overlay-sauna: rgba(0, 0, 0, 0.6);
    --header-overlay: rgba(250, 250, 250, 0.8);

    --bg-header: #C8C5BE;
    --bg-footer: rgba(200, 197, 190, 0.7);
    --bg-skills-widget: rgba(241, 240, 236, 0.9);
    --bg-icons-widget: rgba(241, 240, 236, 0.85);

    --bg-button-decarto: #986A3E;
    --bg-button-mfb: #D2B0B9;
    --bg-button-sauna: #1B261F;

    --typography-dark: #FAFAFA;
    --typography-light-primary: #3E3631;
    --typography-light-secondary: #2B2420;
    --typography-hover: #73665E;

    --font-heading: 'Antic Didone', serif;
    --font-subheading: 'Domine', serif;
    --font-text: 'Raleway', sans-serif;

    
}`, "",{"version":3,"sources":["webpack://./src/vars.css"],"names":[],"mappings":"AAAA;IACI,wDAAyD;IACzD,yDAA2D;IAC3D,yDAA2D;;IAE3D,wCAAwC;IACxC,yCAAyC;IACzC,oDAAoD;IACpD,6CAA6C;IAC7C,uCAAuC;IACvC,sCAAsC;IACtC,0CAA0C;;IAE1C,oBAAoB;IACpB,qCAAqC;IACrC,4CAA4C;IAC5C,4CAA4C;;IAE5C,4BAA4B;IAC5B,wBAAwB;IACxB,0BAA0B;;IAE1B,0BAA0B;IAC1B,mCAAmC;IACnC,qCAAqC;IACrC,2BAA2B;;IAE3B,qCAAqC;IACrC,kCAAkC;IAClC,kCAAkC;;;AAGtC","sourcesContent":[":root {\n    --bg-img-floral: url('/src/assets/Background-floral.jpg');\n    --bg-img-pattern: url('/src/assets/Background-pattern.jpg');\n    --bg-img-decarto: url('/src/assets/Background-decarto.jpg');\n\n    --bg-overlay-main: rgba(20, 19, 17, 0.6);\n    --bg-overlay-stack: rgba(95, 95, 95, 0.5);\n    --bg-overlay-decarto-light: rgba(250, 250, 250, 0.4);\n    --bg-overlay-decarto-dark: rgba(0, 0, 0, 0.2);\n    --bg-overlay-mfb: rgba(72, 72, 72, 0.7);\n    --bg-overlay-sauna: rgba(0, 0, 0, 0.6);\n    --header-overlay: rgba(250, 250, 250, 0.8);\n\n    --bg-header: #C8C5BE;\n    --bg-footer: rgba(200, 197, 190, 0.7);\n    --bg-skills-widget: rgba(241, 240, 236, 0.9);\n    --bg-icons-widget: rgba(241, 240, 236, 0.85);\n\n    --bg-button-decarto: #986A3E;\n    --bg-button-mfb: #D2B0B9;\n    --bg-button-sauna: #1B261F;\n\n    --typography-dark: #FAFAFA;\n    --typography-light-primary: #3E3631;\n    --typography-light-secondary: #2B2420;\n    --typography-hover: #73665E;\n\n    --font-heading: 'Antic Didone', serif;\n    --font-subheading: 'Domine', serif;\n    --font-text: 'Raleway', sans-serif;\n\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
