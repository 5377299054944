// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
    height: 80px;
    width: 100%;
    background-color: var(--bg-footer);
    margin-top: -80px;
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: center;
}

.footer-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
}

.footer-icon-container > li {
    list-style: none;
}

.link-flex {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    color: var(--typography-dark);
    font-size: 20px;
    font-weight: 200;
}

.link-flex:hover {
    color: var(--typography-hover);
}

.center-text {
    margin-top: 0.1rem;
}

.cv-icon {
    margin-bottom: 0.3rem;
}

@media (min-width: 1441px) {
    footer {
        height: 100px;
        margin-top: -100px;
    }

    .link-flex {
        font-size: 24px;
    }
}

@media (min-width: 880px) and (max-width: 1029px) {
    .footer-icon-container {
        gap: 2rem;
    }

    .link-flex {
        font-size: 18px;
    }
}

@media (max-width: 879px) {
    .link-flex > p {
        display: none;
    }
}

@media (max-width: 599px) {
    
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,kCAAkC;IAClC,iBAAiB;IACjB,kBAAkB;IAClB,UAAU;IACV,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,uBAAuB;IACvB,6BAA6B;IAC7B,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI;QACI,aAAa;QACb,kBAAkB;IACtB;;IAEA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,SAAS;IACb;;IAEA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;AACJ;;AAEA;;AAEA","sourcesContent":["footer {\n    height: 80px;\n    width: 100%;\n    background-color: var(--bg-footer);\n    margin-top: -80px;\n    position: relative;\n    z-index: 3;\n    display: flex;\n    justify-content: center;\n}\n\n.footer-icon-container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 2.5rem;\n}\n\n.footer-icon-container > li {\n    list-style: none;\n}\n\n.link-flex {\n    display: flex;\n    gap: 0.5rem;\n    align-items: center;\n    justify-content: center;\n    color: var(--typography-dark);\n    font-size: 20px;\n    font-weight: 200;\n}\n\n.link-flex:hover {\n    color: var(--typography-hover);\n}\n\n.center-text {\n    margin-top: 0.1rem;\n}\n\n.cv-icon {\n    margin-bottom: 0.3rem;\n}\n\n@media (min-width: 1441px) {\n    footer {\n        height: 100px;\n        margin-top: -100px;\n    }\n\n    .link-flex {\n        font-size: 24px;\n    }\n}\n\n@media (min-width: 880px) and (max-width: 1029px) {\n    .footer-icon-container {\n        gap: 2rem;\n    }\n\n    .link-flex {\n        font-size: 18px;\n    }\n}\n\n@media (max-width: 879px) {\n    .link-flex > p {\n        display: none;\n    }\n}\n\n@media (max-width: 599px) {\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
