// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./vars.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    overflow-x: hidden;
}

.btn {
    text-decoration: none;
    border-radius: 5px;
    padding: 0.8rem 1.8rem 0.6rem 1.8rem;
    text-align: center;
    text-wrap: nowrap;
}

.btn:hover,
button:hover {
    cursor: pointer;
}

a {
    text-decoration: none;
}

@media (max-width: 599px) {
    .btn {
        font-size: 14px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,oCAAoC;IACpC,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;;IAEI,eAAe;AACnB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":["@import \"./vars.css\";\n\n.App {\n    overflow-x: hidden;\n}\n\n.btn {\n    text-decoration: none;\n    border-radius: 5px;\n    padding: 0.8rem 1.8rem 0.6rem 1.8rem;\n    text-align: center;\n    text-wrap: nowrap;\n}\n\n.btn:hover,\nbutton:hover {\n    cursor: pointer;\n}\n\na {\n    text-decoration: none;\n}\n\n@media (max-width: 599px) {\n    .btn {\n        font-size: 14px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
